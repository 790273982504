import { createContext, useState, useEffect } from 'react'
//Amplify package
import { Auth, Hub } from 'aws-amplify'

export const UserContext = createContext()
export const UserContextProvider = (props) => {
  const [user, setUser] = useState(null)
  const [progressCircle, setProgressCircle] = useState(true)
  Hub.listen('auth', (data) => {
    switch (data.payload.event) {
      case 'signOut':
        setUser(null)
        break
      case 'cognitoHostedUI':
        break
      default:
        break
    }
  })
  useEffect(() => {
    Hub.listen('auth', ({ payload: { event, data } }) => {
      switch (event) {
        case 'cognitoHostedUI':
          checkUser()
          break
        case 'signOut':
          setUser(null)
          break
      }
    })
    checkUser()
  }, [])
  const checkUser = async () => {
    try {
      const responseUser = await Auth.currentAuthenticatedUser()
      const JWTvalue = responseUser.signInUserSession.getAccessToken();
      /*
      Auth.currentSession()
          .then((data) => {
             console.log('currentData', data);
             console.log(Auth.user.attributes.email);
          }).catch(err => console.log(err));
      */
      let jwt = JWTvalue.payload;
      let client_id = jwt.client_id;

      let curToken = localStorage.getItem('sessionToken');
      let expireTime = localStorage.getItem('sessionExpire');

      let currentTime = Math.floor(Date.now() / 1000);
      if(curToken && expireTime && currentTime < expireTime) {
        // Verify session token at backend
        fetch("https://api.trainingplan.io", {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({ session_id: curToken })
        })
        .then(response => response.json())
        .then(responseData => {
          if(typeof responseData["session"] != "undefined") {
            let sessionToken = responseData["session"];
            let expire = responseData["expire"];
            localStorage.setItem('sessionToken', sessionToken);
            localStorage.setItem('sessionExpire', expire);
            let postpend = ';';
            if(sessionToken == '-1') {
              postpend = ';expires=Thu, 01 Jan 1970 00:00:01 GMT'
            }
            document.cookie = "sessionId=" + sessionToken + "; domain=trainingplan.io; path=/;" + postpend;
          }
        })
        .catch(error => {
          console.error('Error:', error);
        });
      } else {
        // Verify session at backend
        fetch("https://api.trainingplan.io", {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({ client_id: client_id, "jwt": JWTvalue.jwtToken})
        })
        .then(response => response.json())
        .then(responseData => {
          if(typeof responseData["session"] != "undefined") {
            let sessionToken = responseData["session"];
            let expire = responseData["expire"];
            localStorage.setItem('sessionToken', sessionToken);
            localStorage.setItem('sessionExpire', expire); 
            document.cookie = "sessionId=" + sessionToken + "; domain=trainingplan.io; path=/;";
          }
        })
        .catch(error => {
          console.error('Error:', error);
        });
      }
      setUser(responseUser)
      setProgressCircle(false)
    } catch (error) {
      setUser(null)
      setProgressCircle(false)
    }
  }
  return (
    <>
    {progressCircle ? (
      'Loading'
    ) : (
      <UserContext.Provider value={{ user, setUser }}>
      {props.children}
      <div dangerouslySetInnerHTML={{__html: user ? '' : "<a href='#' class='u mr-lg button main w-button starttraining'>Sign In / Sign Up</a>"}}></div>

      </UserContext.Provider>
    )}
    </>
  )
}
