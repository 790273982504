import SelectSearch from 'react-select-search';
import 'react-select-search/style.css'


const options = [
    {name: 'Running', value: 'running'},
    {name: 'Biking', value: 'biking'}
];

function Dashboard() {
  return (
    <div id="wrapper">
      <div class="section overflow-hidden">
        <div class="container mt-lg mb-lg">
          <div class="row">
            <div class="column align-center">
              <h1 class="text-lg text-center">Trainingplan Dashboard</h1>
            </div>
          </div>
        </div>
        <div class="circle"></div>
      </div>
      <div class="col-lg-12 mb-4">
        <div class="card shadow mb-4">
          <div class="card-header py-3">
              <h6 class="m-0 font-weight-bold text-primary">Welcome back to your dashboard!</h6>
          </div>
          <div class="card-body">
            <SelectSearch options={options} search="true" name="sports" placeholder="Choose your sports" />
            How far are you going? (KM) <input type="text" name="distance" /><br />
            When is your race? (Weeks) <input type="text" name="weeks" /><br />
            <p>Generate training plan</p>
            <button class='button btn getTrainingPlanButton'>Generate!</button>
            <hr />
            <textarea class="w-75" id='apiplan' rows="12">
            </textarea>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Dashboard;
