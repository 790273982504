import React, { useContext } from 'react';
import ReactDOM from 'react-dom';
import { UserContext } from './contexts/UserContext'
import { Auth } from '@aws-amplify/auth';
import { AmplifyAuthenticator, AmplifySignOut, withAuthenticator } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import LoginPage from './Pages/LoginPage'
import InnerApp from './Pages/InnerApp'
import logo from './logo.svg';
import './App.css';
import Dashboard from './Dashboard'
import { useState, useEffect } from 'react';

function App({ setUser, signOut}) {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  useEffect(() => {
    const checkAuthStatus = async () => {
      try {
        await Auth.currentAuthenticatedUser();
        setIsAuthenticated(true);
        const script = document.createElement('script');
        script.src = "dashboard.js";
        script.async = true;
        document.body.appendChild(script);

        ReactDOM.render(
          <div id="signout">
            <a href="#" class="u mr-lg button main w-button signoutbtn" onClick={() => Auth.signOut()}>Sign Out</a>
          </div>,
          document.getElementById('loginbox')
        );
        ReactDOM.render(
          <Dashboard />,
          document.getElementById('content')
        );
      } catch (error) {
        setIsAuthenticated(false);
      }
    };

    checkAuthStatus();
  }, []);
}

export default withAuthenticator(App);
